import { graphql, PageProps } from "gatsby"
import { withAuthentication } from "@app/hoc/Authentication"
import Page from "@app/components/Account/LoginRegister/AccountAuth"

export type Props = PageProps<GatsbyTypes.PageAccountLoginLoadingQuery, GatsbyTypes.PageAccountLoginLoadingQueryVariables>

export { Head } from "@components/Head"

export const query = graphql`
  query PageAccountLoginLoading {
    page: sanityPageAccountLoginAndRegister {
      titleLogin
      url
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default withAuthentication(Component)
